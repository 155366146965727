
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { userAccount } from '../models/useraccount/useraccount';
import { UserAccountRegister } from '../models/useraccount/useraccountregister';
import * as moment from 'moment';
import { ForgotPassword } from '../models/useraccount/forgotPassword';

@Injectable({
    providedIn: 'root'
  })
  export class UserAccountService {

    myAppUrl: string;
    myApiUrl: string;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
    constructor(private http: HttpClient) {
        this.myAppUrl = environment.appUrl;
        this.myApiUrl = 'api/v1/SIG/UserAccountSig/';
    }

    getUserbyId(userId: number): Observable<userAccount> {
        return this.http.get<userAccount>(this.myAppUrl + this.myApiUrl + userId, this.httpOptions )
        .pipe(
          retry(1),
          catchError(this.errorHandler)
        );
    }

    updateUser(userAccount): Observable<UserAccountRegister> {
      return this.http.put<UserAccountRegister>(this.myAppUrl + this.myApiUrl + 'update', JSON.stringify(userAccount), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
    }

    forgotPassword(email): Observable<ForgotPassword> {

      return this.http.post<ForgotPassword>(this.myAppUrl + this.myApiUrl + 'UserForgotPassword', JSON.stringify(email), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
      }

  }
