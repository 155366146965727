import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiStatusService {
    private apiUrl = `${environment.appUrl}index.html`;

  constructor(private http: HttpClient) {}

  checkApiStatus(): Observable<boolean> {
    return this.http.get(this.apiUrl, { responseType: 'text' }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
  
}
