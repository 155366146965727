import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { AuthService } from 'src/app/auth/auth.service';
import { role } from 'src/app/models/Enum/role';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    menuItems: Array<any>;
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;

    constructor(
        public menu: MenuService, 
        public settings: SettingsService, 
        public injector: Injector, 
        private authService: AuthService,  
        private routerD: Router
    ) {
       
        this.menuItems = menu.getMenu();
    
        // Controle de acesso temporário
        if (!this.authService.hasRole(role.Administrador)) {
            this.menuCadastros(1);  // Oculta o menu de Cadastros para todos exceto Administradores
            this.menuConfiguracoes(1);  // Oculta o menu de Configurações para todos exceto Administradores
    
            // Desabilita o menu de Lançamentos para DirecaoIAB
            if (this.authService.hasRole(role.DirecaoIAB)) {
                this.menuLancamentos(1);  // Oculta o menu de Lançamentos para o perfil DirecaoIAB
            } else {
                this.menuLancamentos(0);  // Exibe o menu de Lançamentos para todos os outros perfis
            }
    
            // Exibe o menu Export CSV apenas para Administrador e DirecaoIAB
            if (this.authService.hasRole(role.Administrador) || this.authService.hasRole(role.DirecaoIAB)) {
                this.menuExportCSV(0);  // Libera o menu CSV para Administrador e DirecaoIAB
            } else {
                this.menuExportCSV(1);  // Oculta o menu CSV para outros perfis
            }
    
            if (this.authService.hasRole(role.CoordenadorIAB)) {
                this.menuCadastros(0);  // Exibe o menu de Cadastros para CoordenadorIAB
            }
    
            // Controle de acesso aos outros menus
            if (
                this.authService.hasRole(role.CoordenadorIAB) ||
                this.authService.hasRole(role.SupervisorMunicipal) ||
                this.authService.hasRole(role.SecretarioMunicipalEducacao) ||
                this.authService.hasRole(role.DirecaoIAB)
            ) {
                // Liberação de todos os menus, exceto CSV, para perfis específicos
                this.menuRelatoriosQDS(0);
                this.menuRelatoriosSig(0);
            } else if (
                this.authService.hasRole(role.GestorEscolar) ||
                this.authService.hasRole(role.CoordenadorPedagogico) ||
                this.authService.hasRole(role.CoordenadorModoLeitura)
            ) {
                // Liberação parcial dos menus para alguns perfis
                this.menuRelatoriosQDSEscola(0);  // QDS por Escola
                this.menuRelatoriosSig(0);  // Frequência por Turma, Ritmo por Turma, Testes por Turma
                this.menuRelatoriosQDS(1);  // Desativar o menu completo para esses perfis
            } else {
                // Bloquear todos os menus para outros perfis
                this.menuRelatoriosQDS(1);
                this.menuRelatoriosSig(1);
                this.menuRelatoriosQDSEscola(1);
                
            }
        } else {
            // Caso o usuário seja Administrador, libere todos os menus
            this.menuCadastros(0);
            this.menuConfiguracoes(0);
            this.menuLancamentos(0);
            this.menuExportCSV(0);
            this.menuRelatoriosQDS(0);
            this.menuRelatoriosSig(0);
            this.menuRelatoriosQDSEscola(0);
            this.menuRelatorioFarolLancamentos(0);
        }
    }

    menuCadastros(active : number): void {
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Cadastros')), active); //HEADER
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Alunos')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Disciplinas')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Testes')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Coordenadores')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Livros')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Calendários')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Programa')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Perfil')), active);               
    }

    menuLancamentos(active: number): void {
        const lancamentoTexts = [
            'Lançamentos', 
            'Frequência', 
            'Frequência Nível Aluno', 
            'Ritmo', 
            'Teste', 
            'Teste Nível Aluno'
        ];
    
        lancamentoTexts.forEach(text => {
            const itemIndex = this.menuItems.findIndex(i => i.text === text);
            if (itemIndex !== -1 && active === 1) {
                this.menuItems.splice(itemIndex, 1);
            }
        });
    
        console.log('Menu items after menuLancamentos:', this.menuItems);
    }

    menuConfiguracoes(active : number): void {
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Configurações')), active); //HEADER
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Porte das Escolas')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Tipos de Escolas')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Dias Letivos')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Cargos')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Cadastro de Município')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Cadastro de Escolas')), active); 
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Cadastro de Usuário')), active);                                                              
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Tipos de Municípios')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Tipos de Acompanhamento')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Tipos de Programa')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Turnos')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Processo dos Calendários')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Status dos Calendários')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Ano-Mês Referência')), active);                                                            
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Parâmetros Sig')), active);
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Parâmetros')), active);
    }

    menuRelatoriosQDS(active: number): void {
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Quadro Geral')), active); //HEADER
        
    }

    menuExportCSV(active: number): void {
        const exportCsvItem = this.menuItems.find(i => i.text === 'Exportar CSV');
        if (exportCsvItem) {
            const index = this.menuItems.indexOf(exportCsvItem);
            if (index !== -1 && active === 1) {
                this.menuItems.splice(index, 1);
            }
        }
    }

    menuRelatorioFarolLancamentos(active: number): void {
        const farolLancamentosItem = this.menuItems.find(i => i.text === 'Farol de Lançamentos');
        if(farolLancamentosItem){
            const index = this.menuItems.indexOf(farolLancamentosItem);
            if(index !== -1 && active === 1){
                this.menuItems.splice(index, 1);

            }
        }

    }

    menuRelatoriosQDSEscola(active: number): void{
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Quadro por Escola')), active); //HEADER
    }

    menuRelatoriosSig(active: number): void {
        this.menuItems.splice(this.menuItems.indexOf(this.menuItems.find(i => i.text === 'Status de Lançamento')), active); 
    }

   

    ngOnInit() {
        this.router = this.injector.get(Router);

        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.setLayoutSetting('asideToggled', false);
        });

        // enable sidebar autoclose from external clicks
        this.anyClickClose();
    }
    
    

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.setLayoutSetting('asideToggled', false);
            }
        });
    }


    ngOnDestroy() {
        if (this.$doc)
            this.$doc.off(this.sbclickEvent);
    }

    toggleSubmenuClick(event) {
        event.preventDefault();
    
        const target = event.currentTarget;
        const href = target.getAttribute('href');
    
        // Verifica se é um link externo
        if (href && href.startsWith('http')) {
            window.open(href, '_blank');
            return; // Evita qualquer outra manipulação de submenu
        }
    
        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
            let ul = $(event.currentTarget.nextElementSibling);
    
            // hide other submenus
            let parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                let $el = $(el);
                // if element is not a parent or self ul
                if (el !== parentNav[0] && el !== ul[0]) {
                    this.closeMenu($el);
                }
            });
    
            // abort if not UL to process
            if (!ul.length) {
                return;
            }
    
            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });
    
            // toggle UL height
            const ulHeight = ul.css('height')
            if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
                this.closeMenu(ul);
            }
            else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.css('height', 'auto').off('transitionend');
                }).css('height', ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }
        }
    }
    

    // Close menu collapsing height
    closeMenu(elem) {
        elem.css('height', elem[0].scrollHeight); // set height
        elem.css('height', 0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        let self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            let ul = $(event.currentTarget.nextElementSibling);
            let anchor = $(event.currentTarget);

            if (!ul.length) {
                return; // if not submenu return
            }

            let $aside = $('.aside-container');
            let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            let $sidebar = $asideInner.children('.sidebar');
            let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            let floatingNav = ul.clone().appendTo($aside);
            let vwHeight = document.body.clientHeight;

            // let itemTop = anchor.position().top || anchor.offset().top;

            floatingNav
                .addClass('nav-floating')

            // each item has ~40px height
            // multiply to force space for at least N items
            var safeOffsetValue = (40 * 5)
            var navHeight = floatingNav.outerHeight(true) + 2; // 2px border
            var safeOffset = navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

            var displacement = 25; // displacement in px from bottom

            // if not enough space to show N items, use then calculated 'safeOffset'
            var menuTop = (vwHeight - itemTop > safeOffset) ? itemTop : (vwHeight - safeOffset - displacement);

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .css({
                    position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                    top: menuTop,
                    bottom: (floatingNav.outerHeight(true) + menuTop > vwHeight) ? (displacement+'px') : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', function(e) {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    let routeTo = $(this).attr('route');
                    if (routeTo) self.router.navigate([routeTo]);
                });

            this.listenForExternalClicks();

        }

    }

    listenForExternalClicks() {
        let $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }
    isSidebarCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }
    isEnabledHover() {
        return this.settings.getLayoutSetting('asideHover');
    }
}
