import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UserAccountService } from 'src/app/services/useraccount';
import { ForgotPassword } from 'src/app/models/useraccount/forgotPassword';
import { ProcessMensage } from '../../general/common/processMensage';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenssageType } from 'src/app/models/Enum/messageType';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    valForm: FormGroup;

    email: ForgotPassword;

    constructor(public settings: SettingsService, private router: Router, fb: FormBuilder,  private userService: UserAccountService,private spinnerService: NgxSpinnerService) {
        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])]
        });
    }

    ngOnInit() {
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        this.spinnerService.show();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
          this.email = Object.assign({}, this.valForm.value);
          this.userService.forgotPassword(this.email).subscribe((data: any) => {
            if (data.registers == null)
              ProcessMensage.Mensage(data, this.spinnerService)

            this.spinnerService.hide();

            this.router.navigate(['/']);

          },
            error => {
              ProcessMensage.Mensage({messageType: MenssageType.Error, message: 'Ocorreu um erro. Por favor, entrar em contato com o SAC para regularização.'}, this.spinnerService)
            });
          //swal('Atenção!', 'Verifique sua caixa de correio e siga as instruções contidas nele para prosseguir com a redefinição de sua senha de acesso.', 'info');

        }
    }
}
