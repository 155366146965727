import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { role } from '../models/Enum/role';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGestor implements CanActivate, CanActivateChild, CanLoad {
  constructor(private util: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log("AuthGuardGestor canActivate called");
    if (!this.util.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return this.hasRequiredRole();
  }

  canActivateChild(): boolean {
    return this.hasRequiredRole();
  }

  canLoad(): boolean {
    return this.hasRequiredRole();
  }

  private hasRequiredRole(): boolean {
    return (this.util.hasRole(role.Administrador) ||
            this.util.hasRole(role.CoordenadorIAB) ||
            this.util.hasRole(role.DirecaoIAB) ||
            this.util.hasRole(role.GestorEscolar) ||
            this.util.hasRole(role.SupervisorMunicipal) ||
            this.util.hasRole(role.SecretarioMunicipalEducacao) ||
            this.util.hasRole(role.CoordenadorPedagogico) || 
            this.util.hasRole(role.CoordenadorModoLeitura));
  }
}