import { MenssageType } from 'src/app/models/Enum/messageType';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

export class ProcessMensage{

  static Mensage(mensageData: any, spinnerService: NgxSpinnerService){
    spinnerService.hide();
    if(mensageData.messageType == MenssageType.Success){
      swal('Sucesso!', mensageData.message, 'success');//Mensagem de sucesso
    }else if(mensageData.messageType == MenssageType.Warning){
      swal('Atenção!', mensageData.message, 'warning');//Mensagem de aviso
    }else{
      swal('Ocorreu um erro!', mensageData.message, 'error');//Mensagem de erro
    }
  }
}
