import { Component, OnInit } from '@angular/core';
import { UserblockService } from './userblock.service';
import { SecurityService } from 'src/app/services/security.service';
import { userLogin } from '../../../models/userlogin';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    userLogin: userLogin;    
    constructor(public userblockService: UserblockService, private securityService: SecurityService) {
        this.user = {
            name: window.sessionStorage.getItem('name'),
            userType: window.sessionStorage.getItem('rolename'),
            email: window.sessionStorage.getItem('email'),
            customerId: window.sessionStorage.getItem('customerId'),
            picture: 'assets/img/user/user.png'
        };
    }

    ngOnInit() {
        this.loadUserLogin();
    }

    loadUserLogin() {
        /*
        //Check if toke is valid
        this.securityService.checkTokenIsValid(+window.sessionStorage.getItem('token')).subscribe((data: userLogin) => {
            //Se token inválido, retornar usuário para tela inicial
            this.router.navigate(['/']); 
        });

        */
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
